<template lang="">
    <div>
        <ListTabel 
        :OAtype="OAtype"
        />
    </div>
</template>
<script>
import ListTabel from '../component/index.vue'
export default {
    components:{ListTabel},
    data() {
        return {
            OAtype:'2',//区分页面 1-台账 2-待办 3-已办
        }
    },
}
</script>
<style lang="">
    
</style>